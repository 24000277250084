import {Box, Button, Flex, Image, Text} from "@chakra-ui/react";
import React from "react";

export default function MessageViewer({message}) {
	if (!message) return "";
	async function getFile(fileType) {
		let url = process.env.REACT_APP_API_URL + "/file/" + fileType + "?id=" + message.code;
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", message.code + "-" + fileType + ".jpg");
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);

		/* let response = await fetch(
			process.env.REACT_APP_API_URL +
				"/file/" +
				fileType +
				"?id=" +
				message.code
		)
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute(
					"download",
					message.code + "-" + fileType + ".jpg"
				);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			})
			.catch(e => {
				console.error(e);
			});
		console.log(response); */
	}

	function getEmbeddedFile() {
		if (!message.fileName) return "";
		let ext = message.fileName.split(".").pop().toLowerCase();

		/* IMAGE */
		let imageExt = ["jpg", "jpeg", "gif", "png", "heic"];
		if (imageExt.indexOf(ext) !== -1) {
			return (
				<Image src={process.env.REACT_APP_API_URL + "/messagefile/get/" + message.fileName}></Image>
			);
		}
		let videoExt = ["mov", "mp4", "wav"];
		if (videoExt.indexOf(ext) !== -1) {
			return (
				<video
					controls
					src={process.env.REACT_APP_API_URL + "/messagefile/get/" + message.fileName}></video>
			);
		}

		const src = `${process.env.REACT_APP_API_URL}/messagefile/get/${message.fileName}`;

		return (
			<iframe
				src={src}
				title="Embedded Content"
				sandbox="allow-scripts allow-same-origin"
				style={{border: "none", width: "100%", height: "500px"}}></iframe>
		);
	}

	return (
		<>
			<Flex
				flexDir={"column"}
				gap={10}
				mt={10}
				p={5}
				bgColor={"rgba(0,0,0,0.9)"}
				borderRadius={"15px"}
				className="messageViewer">
				{getEmbeddedFile()}
				<Text
					color={"white"}
					fontSize={20}
					fontWeight={"bold"}
					textAlign={"center"}
					dangerouslySetInnerHTML={{
						__html: parseMessageHTML(message.message),
					}}></Text>
			</Flex>
			<Box>
				<Button
					display={message.zertifikat ? "block" : "none"}
					w={"100%"}
					mt={5}
					onClick={() => {
						getFile("zertifikat");
					}}>
					Zertifikat herunterladen
				</Button>
				<Button
					display={message.poster ? "block" : "none"}
					w={"100%"}
					mt={5}
					onClick={() => {
						getFile("poster");
					}}>
					Poster herunterladen
				</Button>
			</Box>
		</>
	);
}

export function parseMessageHTML(string) {
	/* Früher mussten zwei Bindestriche für einen Zeilenumbruch
	benutzt werden, deshalb sind diese hier noch verzeichnet. */
	string = window.twemoji.parse(string, {
		base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
	});
	string = "<p>" + string.replace(/\r\n|\r|\n/g, "<br />") + "</p>";
	string = string.replaceAll("--", "</p><p>").replaceAll("—", "</p><p>");
	return string;
}
