import {
	Box,
	Button,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	Heading,
	Input,
	Text,
} from "@chakra-ui/react";
import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function NamesPage() {
	return (
		<Flex
			position={"fixed"}
			transition={"all 1s ease-in-out"}
			top="0"
			left="0"
			height="100%"
			w={"100vw"}
			bgColor="rgba(0,0,0,0.9)"
			zIndex="2"
			justifyContent={"center"}
			alignItems={"center"}>
			<Flex
				borderTop={"9px solid"}
				borderColor={"brand.600"}
				w={["95%", "70%", "400px"]}
				bgColor={"gray.700"}
				p={7}
				borderRadius={10}
				color={"white"}
				flexDir={"column"}>
				<Heading as="h1" size={"lg"} mb={3} textAlign={"center"}>
					Hochzeitsnachricht
				</Heading>
				<Text>
					Gib hier die Vornamen des Hochzeitspaares ein, um die
					Hochzeitsnachricht zu verfolgen! Die Reihenfolge ist dabei
					egal, achte nur auf die richtige Schreibweise.
				</Text>
				<FormControl mt={5}>
					<CodeOverlay />
				</FormControl>
			</Flex>
		</Flex>
	);
}

function CodeOverlay() {
	const [partnerId, setPartnerId] = useState("");
	const location = useLocation();
	const [name1Input, setName1Input] = useState(
		localStorage.getItem("name1") || ""
	);
	const [name2Input, setName2Input] = useState(
		localStorage.getItem("name2") || ""
	);

	useEffect(() => {
		localStorage.setItem("name1", name1Input);
	}, [name1Input]);

	useEffect(() => {
		localStorage.setItem("name2", name2Input);
	}, [name2Input]);

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		setPartnerId(query.get("partnerId") || "");
	}, [location.search]);

	async function getNamesCode() {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}/message/names?name1=${name1Input}&name2=${name2Input}&partnerId=${partnerId}`
			);
			const data = await response.json();
			if (data && data.code) {
				window.location.href = `https://live.dearuniverse.de?code=${data.code}`;
			} else {
				// Handle no matching entry case here
			}
		} catch (error) {
			console.error(error);
			alert(
				"Leider konnten wir keine Nachricht finden. Überprüfe nochmal Deine Schreibweise.",
				error
			);
		}
	}

	return (
		<>
			<FormLabel htmlFor="name1Input" fontFamily={"inherit"}>
				Vorname 1
			</FormLabel>
			<Input
				mb={3}
				id="name1Input"
				type={"text"}
				value={name1Input}
				placeholder={"Pam"}
				onChange={e => setName1Input(e.target.value)}></Input>
			<FormLabel htmlFor="name2Input" fontFamily={"inherit"}>
				Vorname 2
			</FormLabel>
			<Input
				id="name2Input"
				type={"text"}
				value={name2Input}
				placeholder={"Jim"}
				onChange={e => setName2Input(e.target.value)}></Input>
			<Button
				disabled={name1Input.length === 0 || name2Input.length === 0}
				onClick={getNamesCode}
				backgroundColor={"#E2B007"}
				colorScheme={"brand"}
				color={"initial"}
				w={"100%"}
				mt={5}>
				Weiter
			</Button>
		</>
	);
}
