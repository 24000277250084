import {Box, Text, Image, Flex, Button} from "@chakra-ui/react";
import React from "react";

export default function CheckMessage({userInputs}) {
	// Format date into a readable format
	const dateObj = new Date(userInputs.date);
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit",
	};
	const locale = "de-DE";
	const date = dateObj.toLocaleDateString(locale, options);
	const {message, fileName} = userInputs;

	// Determining the file type based on the file extension
	const fileType = fileName?.split(".")?.pop().toLowerCase() || null;

	// Choosing the right component for the file type
	let MediaComponent;
	switch (fileType) {
		case null:
			break;
		case "jpg":
		case "jpeg":
		case "png":
		case "gif":
			MediaComponent = (
				<Image
					className="mediaPreview"
					mx={"auto"}
					objectFit={"contain"}
					src={
						process.env.REACT_APP_API_URL +
						"/messagefile/get/" +
						fileName
					}
					alt="Bild zur Nachricht"
					boxSize="100px"
				/>
			);
			break;
		case "mp4":
		case "mov":
			MediaComponent = (
				<video
					className="mediaPreview"
					mx={"auto"}
					objectFit={"contain"}
					controls
					src={
						process.env.REACT_APP_API_URL +
						"/messagefile/get/" +
						fileName
					}
					width="100%"
				/>
			);
			break;
		case "mp3":
		case "wav":
			MediaComponent = (
				<audio
					className="mediaPreview"
					controls
					src={
						process.env.REACT_APP_API_URL +
						"/messagefile/get/" +
						fileName
					}
				/>
			);
			break;
		default:
			MediaComponent = <Text>{fileName}</Text>;
	}

	return (
		<Box>
			<Box
				borderRadius={"sm"}
				p={3}
				bgColor={"blue.50"}
				border={"2px solid"}
				borderColor={"blue.100"}>
				<Text color={"blue.600"} fontWeight="bold">
					Sendedatum:
				</Text>
				<Text>{date} Uhr</Text>

				<Text color={"blue.600"} fontWeight="bold">
					Nachricht:
				</Text>
				<Text>{message}</Text>
				{!!MediaComponent && (
					<>
						<Text color={"blue.600"} fontWeight="bold">
							Datei:
						</Text>

						<Box w={"100%"}>{MediaComponent}</Box>
					</>
				)}
			</Box>

			<Text mt={5}>
				Wenn alles gut aussieht, kannst Du auf den folgenden Button
				drücken und so die Nachricht permanent mit Deinem Schmuckstück
				verknüpfen. 🌌
			</Text>
		</Box>
	);
}
