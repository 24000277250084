import {Box, Flex} from "@chakra-ui/react";
import {
	faArrowLeft,
	faArrowRight,
	faFastBackward,
	faFastForward,
	faHistory,
	faPlay,
	faRocket,
	faStar,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export default function Control({dispatchCommand}) {
	return (
		<Flex
			w="100vw"
			position={"absolute"}
			mb={["35px","17px"]}
			bottom="0px"
			justifyContent="center"
			alignItems={"center"}>
			<Flex bgColor={"gray.700"} p={[1,2,3]} borderRadius={"100px"} gap={[1,2,3]}>
				<ControlBtn icon={faFastBackward} onClick={()=>{dispatchCommand("slower")}}/>
				{/* <ControlBtn icon={faArrowLeft} /> */}
				<ControlBtn icon={faHistory} onClick={()=>{dispatchCommand("reset")}} />
				{/* <ControlBtn icon={faPlay} onClick={()=>{dispatchCommand("relaunch")}} /> */}
				{/* <ControlBtn icon={faArrowRight} /> */}
				<ControlBtn icon={faFastForward} onClick={()=>{dispatchCommand("faster")}} />
			</Flex>
		</Flex>
	);
}

function ControlBtn({icon, onClick}) {
	return (
		<Flex
			onClick={onClick}
			_hover={{transform: "scale(1.1)", filter: "brightness(1.5)"}}
			w={["40px","50px"]}
			h={["40px","50px"]}
			borderRadius={"100px"}
			bgColor={"gray.900"}
			fontSize={["20px","28px"]}
			color={"white"}
			justifyContent={"center"}
			alignItems={"center"}
			cursor="pointer">
			<FontAwesomeIcon icon={icon} />
		</Flex>
	);
}
