import {Box, Flex, Image} from "@chakra-ui/react";
import React from "react";

export default function Header() {
	return (
		<Flex
			py={5}
			px={4}
			position="absolute"
			top={"0px"}
			justifyContent={"flex-end"}
			w={"100vw"}>
			<Image
				src={"/images/Logo Transparent.png"}
				h={["40px", "45px", "50px"]}
			/>
		</Flex>
	);
}
