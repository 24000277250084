import {Box, Flex, Heading} from "@chakra-ui/react";
import {
	faBars,
	faEnvelope,
	faLocationPin,
	faMapMarkerAlt,
	faMapPin,
	faSortNumericUpAlt,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import MessagePicker from "./MessagePicker";
import MessageViewer from "./MessageViewer";
import Milestones from "./Milestones";
import Stats from "./Stats";

export default function Menu({
	animationData,
	focusAnimation,
	message,
	setDate,
	date,
	mileStonesData,
}) {
	const [menuOpen, setMenuOpen] = useState(false);
	const [menuContent, setMenuContent] = useState("");
	const [currIndex, setCurrIndex] = useState(0);

	useEffect(() => {
		const iframeClick = () => {
			setMenuOpen(false);
		};
		document
			.getElementById("animationIFrame")
			?.addEventListener("click", iframeClick);
		return () => {
			document
				.getElementById("animationIFrame")
				?.removeEventListener("click", iframeClick);
		};
	}, [menuOpen]);

	return (
		<Flex
			pos={"absolute"}
			backdropFilter={"blur(3px)"}
			bgColor={"#101215b0"}
			h={"100vh"}
			w={["100vw", "100vw", "50vw", "40vw", "30%"]}
			top={"0px"}
			left={"0px"}
			transform={!menuOpen ? "translateX(-100%)" : "translateX(0%)"}
			transition={"transform 0.5s cubic-bezier(0.73, 0.12, 0.22, 0.86)"}>
			<MenuBtn
				icon={faSortNumericUpAlt}
				order={0}
				setMenuOpen={setMenuOpen}
				setMenuContent={setMenuContent}
				menuOpen={menuOpen}
				menuContent={menuContent}
				menuString={"stats"}></MenuBtn>
			<MenuBtn
				icon={faMapMarkerAlt}
				order={1}
				setMenuOpen={setMenuOpen}
				setMenuContent={setMenuContent}
				menuOpen={menuOpen}
				menuContent={menuContent}
				menuString={"milestones"}></MenuBtn>
			<MenuBtn
				icon={faEnvelope}
				order={2}
				setMenuOpen={setMenuOpen}
				setMenuContent={setMenuContent}
				menuOpen={menuOpen}
				menuContent={menuContent}
				menuString={"message"}></MenuBtn>
			<MenuContentWrapper
				title="Statistik"
				active={menuContent === "stats"}>
				<Stats animationData={animationData} />
				<Heading color={"white"} as="h3" size={"md"} mt={10} mb={2}>
					Deine Nachrichten
				</Heading>
				<MessagePicker
					message={message}
					setDate={setDate}
					date={date}
				/>
			</MenuContentWrapper>
			<MenuContentWrapper
				title="Meilensteine"
				active={menuContent === "milestones"}>
				<Milestones
					setMenuOpen={setMenuOpen}
					focusAnimation={focusAnimation}
					currIndex={currIndex}
					setCurrIndex={setCurrIndex}
					mileStonesData={mileStonesData}
				/>
			</MenuContentWrapper>
			<MenuContentWrapper
				title="Deine Nachricht"
				active={menuContent === "message"}>
				<MessageViewer message={message} />
			</MenuContentWrapper>
		</Flex>
	);
}

function MenuBtn({
	order,
	setMenuOpen,
	setMenuContent,
	menuOpen,
	icon,
	menuString,
	menuContent,
}) {
	return (
		<Flex
			transition={"transform 0.5s ease-in-out"}
			transform={[
				menuOpen ? "translateX(-10px)" : "translateX(120%)",
				null,
				"translateX(120%)",
			]}
			w={"50px"}
			h={"50px"}
			top={20 + order * 62 + "px"}
			right={"0px"}
			zIndex={2}
			borderRadius={"100px"}
			bgColor={
				menuString === menuContent && menuOpen ? "gray.700" : "gray.900"
			}
			position={"absolute"}
			fontSize={"26px"}
			color={"white"}
			justifyContent={"center"}
			alignItems={"center"}
			cursor="pointer"
			_hover={{
				transform: [
					(menuOpen ? "translateX(-10px)" : "translateX(120%)") +
						" scale(1.08)",
					null,
					"translateX(120%) scale(1.08)",
				],
				filter: "brightness(1.4)",
			}}
			onClick={() => {
				if (menuString === menuContent || !menuOpen) {
					setMenuOpen(!menuOpen);
				}
				setMenuContent(menuString);
			}}>
			<FontAwesomeIcon icon={icon} />
		</Flex>
	);
}

function MenuContentWrapper({title, children, active}) {
	return (
		<Flex
			overflow={"auto"}
			className={"menuContentWrapper"}
			w={"100%"}
			h={"100%"}
			px={[2, null, 4, 7]}
			py={[4, 5, 7]}
			flexDir={"column"}
			display={`${active ? "flex" : "none"}`}>
			<Heading as="h2" textAlign={"center"} zIndex="1" color={"white"}>
				{title}
			</Heading>
			{children}
		</Flex>
	);
}
