import {Box, Flex, Text} from "@chakra-ui/react";
import React from "react";

export default function MessagePicker({message, setDate, date}) {
	if (!message || !message.senddates) return "";
	return (
		<Flex
			flexDir={"column"}
			p={3}
			gap={3}
			maxHeight={"300px"}
			overflowY={"scroll"}
			borderRadius={10}
			boxShadow={"inset 1px 1px 8px rgba(0,0,0,0.7)"}
			bgColor={"gray.700"}>
			{message.senddates.map(senddate => {
				return (
					<Message
						key={"senddate" + senddate.id}
						senddate={senddate}
						setDate={setDate}
						date={date}
					/>
				);
			})}
		</Flex>
	);
}

function Message({senddate, setDate, date}) {
	return (
		<Flex
			w={"100%"}
			h={"50px"}
			p={3}
			borderRadius={10}
			boxShadow={"1px 1px 5px rgba(0,0,0,0.3)"}
			alignItems={"center"}
			filter={senddate.date === date ? "brightness(1.2)" : ""}
			_hover={{bgColor: "gray.800", transform: "scale(1.01)"}}
			justifyContent={"space-between"}
			onClick={() => {
				setDate(senddate.date);
			}}
			bgColor={"gray.900"}
			cursor={"pointer"}>
			<Text color={"white"}>
				{new Date(senddate.date).toLocaleString()}
			</Text>
			<Flex
				justifyContent={"center"}
				alignItems={"center"}
				w={"90px"}
				h={"30px"}
				borderRadius={"20px"}
				bgColor={senddate.status === "sent" ? "green.600" : "gray.600"}>
				<Text as="span">
					{senddate.status === "sent" ? "gesendet" : "geplant"}
				</Text>
			</Flex>
		</Flex>
	);
}
