import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {ChakraProvider} from "@chakra-ui/react";
import RouterApp from "./RouterApp";
import theme from "./theme";

ReactDOM.render(
	<React.StrictMode>
		<ChakraProvider theme={theme}>
			<RouterApp />
		</ChakraProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
