import {BrowserRouter as Router} from "react-router-dom";
import App from "./App";

export default function RouterApp() {
	return (
		<Router>
			<App />
		</Router>
	);
}
