import {Box, Flex} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import "./App.css";
import Control from "./Components/Control";
import Header from "./Components/Header";
import Main from "./Components/Main";
import Menu from "./Components/Menu";
import CodeOverlay from "./Components/CodeOverlay";
import NamesPage from "./NamesPage";
import {Route, Routes, useLocation} from "react-router-dom";
import NfcPage from "./Components/nfc/NfcPage";

export default function App() {
	const [animationData, setAnimationData] = useState({});
	const [focusLy, setFocusLy] = useState(null);
	const [code, setCode] = useState("");
	const [message, setMessage] = useState(null);
	const [date, setDate] = useState(0);
	const [mileStones, setMileStones] = useState(null);
	const location = useLocation();

	function focusAnimation(val, unit) {
		if (unit === "km") {
			val = val * 1.0570008340247e-13;
		}
		setFocusLy(val);
	}

	useEffect(() => {
		if (code === "") return;
		getMessage();
	}, [code]);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		let codeParam = params.get("code") || params.get("mapid");

		if (codeParam) {
			setCode(codeParam);
		}
	}, [location]);

	useEffect(() => {
		if (!message) return;
		let interval = setInterval(() => {
			let newMessage = {...message};
			for (let i = 0; i < message.senddates.length; i++) {
				const senddate = message.senddates[i];
				let diff =
					new Date().getTime() - new Date(senddate.date).getTime();
				if (diff >= 0 && senddate.status !== "sent") {
					newMessage.senddates[i].status = "sent";
					setMessage(newMessage);
				}
			}
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, [message]);

	function findGetParameter(parameterName) {
		var result = null,
			tmp = [];
		var items = window.location.search.slice(1).split("&");
		for (var index = 0; index < items.length; index++) {
			tmp = items[index].split("=");
			if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
		}
		return result;
	}

	async function getMessage() {
		try {
			let newMessage = await fetch(
				process.env.REACT_APP_API_URL + "/message/" + code
			);
			newMessage = await newMessage.json();
			setDate(newMessage.senddates[0].date);
			setMessage(newMessage);
			console.log(newMessage);
		} catch (error) {
			console.error(error);
			alert("Es gabe einen Fehler...", error);
		}
	}

	const noUi = findGetParameter("noui");

	return (
		<Box
			pos={"relative"}
			bgColor={"black"}
			h={"100%"}
			w={"100vw"}
			overflowX={"hidden"}>
			<Routes>
				<Route path="/names" element={<NamesPage />} />
				<Route path="/nfc" element={<NfcPage />} />
				<Route
					path="/"
					element={
						<>
							<CodeOverlay
								setCode={setCode}
								hide={
									message !== null ||
									findGetParameter("code") ||
									findGetParameter("mapid")
								}></CodeOverlay>
							<Flex direction={"column"} h={"100%"}>
								{!noUi && <Header />}
								<Main
									animationData={animationData}
									date={date}
									setAnimationData={setAnimationData}
									focusLy={focusLy}
									mileStones={mileStones}
									setMileStones={setMileStones}
									noUi={noUi}
								/>
							</Flex>
							{!noUi && (
								<Menu
									animationData={animationData}
									focusAnimation={focusAnimation}
									setDate={setDate}
									message={message}
									mileStonesData={mileStones}
									date={date}
								/>
							)}
						</>
					}
				/>
			</Routes>
		</Box>
	);
}
