import {Box, Button, Flex, Heading, Image, Text} from "@chakra-ui/react";
import {
	faArrowLeft,
	faArrowRight,
	faEllipsisV,
	faEye,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";

export default function Milestones({
	currIndex,
	setCurrIndex,
	focusAnimation,
	setMenuOpen,
	menuOpen,
	mileStonesData,
}) {
	if (!mileStonesData) return "";

	return (
		<Flex flexDir={"column"} flex={1}>
			{mileStonesData.map((milestone, i) => {
				return (
					<MileStone
						key={"milestone" + i}
						focusAnimation={focusAnimation}
						data={milestone}
						total={mileStonesData.length}
						self={i}
						currIndex={currIndex}
						setCurrIndex={setCurrIndex}
						setMenuOpen={setMenuOpen}
					/>
				);
			})}
			<Box mt={"auto"} w={"100%"}>
				<Button
					w={"100%"}
					fontSize={["small", "initial"]}
					onClick={() => {
						let nextMilestone = mileStonesData.find(m => {
							return (
								window.animationData.nextMilestone.name ===
								m.name
							);
						});
						let nextIndex = mileStonesData.indexOf(nextMilestone);
						setCurrIndex(nextIndex);
						focusAnimation(
							nextMilestone.dist,
							nextMilestone.einheit
						);
					}}>
					Nächster Meilenstein auf der Reise
				</Button>
			</Box>
		</Flex>
	);
}

function MileStone({
	data,
	total,
	self,
	currIndex,
	setCurrIndex,
	focusAnimation,
	setMenuOpen,
}) {
	const [cardOpen, setCardOpen] = useState(false);
	const [imageOpen, setImageOpen] = useState(false);
	const [display, setDisplay] = useState(true);
	const borderRadius = 20;

	// useEffect(() => {
	//   let timeout = setTimeout(()=>{
	// 	if(currIndex > self){
	// 		setDisplay(false);
	// 	}
	//   },500)

	//   if(currIndex === self){
	// 	  setDisplay(true);
	//   }

	//   return () => {
	// 	clearTimeout(timeout)
	//   }
	// }, [currIndex])

	return (
		<Flex
			pointerEvents={self === currIndex ? "all" : "none"}
			overflow={"hidden"}
			borderRadius={borderRadius}
			zIndex={1}
			transition={"all 0.3s ease-in-out"}
			display={self !== currIndex ? "none" : "flex"}
			mt={0}
			flexDir={"column"}>
			<Image
				borderRadius={borderRadius}
				border={"1px solid #222"}
				w={"100%"}
				minH={"250px"}
				maxH={"250px"}
				flex={"1 0 " + imageOpen ? "300px" : "250px"}
				position={"relative"}
				overflow="hidden"
				src={"/images/" + data.picurl}
				objectFit="cover"></Image>
			<Flex
				p={5}
				flexDir="column"
				gap={5}
				justifyContent={"space-between"}
				flex="1">
				<Heading
					color={"white"}
					as="h3"
					mb={0}
					size={"lg"}
					textAlign="center">
					{data.name}
				</Heading>
				<Box overflowY="auto">
					<Text
						color={"white"}
						fontSize={"19px"}
						opacity={0.5}
						as={"p"}>
						<FontAwesomeIcon icon={faArrowRight} />{" "}
						{data.dist.toLocaleString()}{" "}
						{data.einheit === "km" ? "Kilometer" : "Lichtjahre"}
					</Text>
					<Text
						color={"white"}
						fontSize={"19px"}
						as={"span"}
						dangerouslySetInnerHTML={{
							__html: data.factTxt,
						}}></Text>
				</Box>
				<Flex justifyContent={"space-between"}>
					<CardBtn
						icon={faArrowLeft}
						onClick={() => {
							if (currIndex !== self) return;
							if (currIndex === 0) return;
							setCurrIndex(currIndex - 1);
							setCardOpen(false);
							setImageOpen(false);
						}}
					/>
					{/* <CardBtn
						icon={faEllipsisV}
						onClick={() => {
							if (currIndex !== self) return;
							setCardOpen(!cardOpen);
						}}
					/> */}
					<CardBtn
						icon={faEye}
						onClick={() => {
							console.log(currIndex, self);
							if (currIndex !== self) return;
							focusAnimation(data.dist, data.einheit);
							if (window.innerWidth < 768) {
								setMenuOpen(false);
							}
						}}
					/>
					<CardBtn
						icon={faArrowRight}
						onClick={() => {
							console.log(currIndex, total);
							if (currIndex !== self) return;
							setCardOpen(false);
							setImageOpen(false);
							if (currIndex + 1 === total) {
								setTimeout(() => {
									setCurrIndex(0);
								}, 500);
								setCurrIndex(currIndex + 1);
							} else {
								setCurrIndex(currIndex + 1);
							}
						}}
					/>
				</Flex>
			</Flex>
		</Flex>
	);
}

function CardBtn({icon, onClick}) {
	return (
		<Flex
			alignItems={"center"}
			justifyContent={"center"}
			w={"50px"}
			h={"50px"}
			fontSize={"20px"}
			borderRadius="full"
			onClick={onClick}
			color={"white"}
			bgColor={"gray.700"}>
			<FontAwesomeIcon icon={icon} />
		</Flex>
	);
}
