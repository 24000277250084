import {
	Box,
	Button,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	Heading,
	Input,
	Text,
} from "@chakra-ui/react";
import React, {useState} from "react";

export default function CodeOverlay({setCode, hide}) {
	const [codeInput, setCodeInput] = useState("");
	return (
		<Flex
			position={"fixed"}
			opacity={hide ? "0" : "1"}
			transition={"all 1s ease-in-out"}
			pointerEvents={hide ? "none" : "initial"}
			top="0"
			left="0"
			height="100%"
			w={"100vw"}
			bgColor="rgba(0,0,0,0.9)"
			zIndex="2"
			justifyContent={"center"}
			alignItems={"center"}>
			<Flex
				borderTop={"9px solid"}
				borderColor={"lightBlue"}
				w={["95%", "70%", "400px"]}
				bgColor={"white"}
				p={7}
				borderRadius={10}
				flexDir={"column"}>
				<Heading as="h1" size={"lg"} textAlign={"center"}>
					Dein Dashboard
				</Heading>
				<Text>
					Hier kannst Du die ewige Reise Deiner Nachricht verfolgen!
				</Text>
				<FormControl mt={5}>
					<FormLabel htmlFor="codeInput" fontFamily={"inherit"}>
						Dein Code
					</FormLabel>
					<Input
						id="codeInput"
						type={"text"}
						value={codeInput}
						placeholder={"XXXXXXX"}
						onKeyPress={e => {
							if (e.key === "Enter") {
								setCode(codeInput);
							}
						}}
						onChange={e => {
							setCodeInput(e.target.value);
						}}></Input>
					<FormHelperText>
						Gib hier Deinen siebenstelligen Nachrichtencode ein.
					</FormHelperText>
					<Button
						disabled={codeInput.length <= 6}
						onClick={() => {
							setCode(codeInput);
						}}
						color={"initial"}
						w={"100%"}
						mt={5}>
						Ansehen
					</Button>
					<Button
						variant="outline"
						w={"100%"}
						onClick={() => {
							setCode("demo");
						}}
						mt={5}>
						Demo
					</Button>
				</FormControl>
			</Flex>
		</Flex>
	);
}
