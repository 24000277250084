import {
	Box,
	Button,
	Flex,
	Heading,
	Image,
	Text,
	Input,
	useToast,
} from "@chakra-ui/react";
import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import NewMessage from "./NewMessage";
import ExistingMessage from "./ExistingMessage";
import CheckMessage from "./CheckMessage";

function Screen({component, buttons, icon, heading, children, setCurrentStep}) {
	return (
		<Flex
			textAlign={"center"}
			minH={"100vh"}
			minW={"100vw"}
			bgColor={"white"}
			flexDir={"column"}
			gap={5}
			alignItems={"center"}
			overflow={"auto"}
			p={5}>
			<Image
				onClick={() => {
					setCurrentStep(0);
				}}
				src="/images/logo_transparent_black_r.svg"
				maxW={"200px"}
			/>
			<Heading as={"h1"}>{heading}</Heading>
			<Box minH={"30vh"}>{component}</Box>
			{buttons.map((button, i) => {
				return (
					<Button
						size={button.size || "lg"}
						width={"100%"}
						onClick={() => {
							if (button.onClick) button.onClick();
						}}
						variant={button.variant}
						key={"button" + i}>
						{button.text}
					</Button>
				);
			})}
		</Flex>
	);
}

export default function NfcPage() {
	const [ncode, setNcode] = useState(null);
	const [currentStep, setCurrentStep] = useState(0);
	const [userInputs, setUserInputs] = useState({
		messageCode: "",
		email: "",
		date: new Date().toISOString(),
		message: "",
		fileName: "",
	});
	const [messageExists, setMessageExists] = useState(false);
	const [file, setFile] = useState(null);
	const location = useLocation();
	const navigate = useNavigate();
	const toast = useToast();

	const [blankScreen, setBlankScreen] = useState(true);
	// Extract the ncode from the URL parameters
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		setNcode(params.get("ncode"));
	}, [location]);

	// Check if NFC tag is already linked
	useEffect(() => {
		if (ncode) {
			fetch(`${process.env.REACT_APP_API_URL}/nfc/${ncode}`)
				.then(response => response.json())
				.then(data => {
					console.log(data);
					if (data.error) {
						alert(
							data.error + ". Bitte wende Dich an den Support."
						);
					} else if (data.result === "exists") {
						setBlankScreen(false);
					} else if (data.code) {
						navigate(`/?code=${data.code}`);
					}
				})
				.catch(() => {
					alert(
						"Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Support."
					);
				});
		}
	}, [ncode]);

	function changeUserInputs(key, value) {
		const newUserInputs = {...userInputs};
		newUserInputs[key] = value;
		setUserInputs(newUserInputs);
	}

	const checkData = () => {
		if (messageExists) {
			fetch(
				`${process.env.REACT_APP_API_URL}/message/${userInputs.messageCode}`
			)
				.then(response => response.json())
				.then(data => {
					if (!data.code) {
						toast({
							title: "Fehler.",
							description: "Die Nachricht wurde nicht gefunden.",
							status: "error",
							duration: 4000,
							isClosable: true,
						});
					} else {
						setUserInputs({
							messageCode: data.code,
							email: data.email,
							date: new Date(data.date),
							message: data.message,
							fileName: data.fileName,
						});
						setCurrentStep(currentStep + 1);
					}
				})
				.catch(() => {
					toast({
						title: "Fehler.",
						description:
							"Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Support.",
						status: "error",
						duration: 4000,
						isClosable: true,
					});
				});
		} else {
			const now = new Date();
			const inputDate = new Date(userInputs.date);
			const differenceInMinutes =
				(inputDate.getTime() - now.getTime()) / (1000 * 60);

			if (differenceInMinutes < 30) {
				toast({
					title: "Wir brauchen mindestens 30 Minuten...",
					description:
						"Das Datum muss 30 Minuten in der Zukunft liegen, damit Deine Nachricht von allen Antennen erfasst werden kann.",
					status: "info",
					duration: 4000,
					isClosable: true,
				});
				return false;
			}

			if (!userInputs.fileName && !userInputs.message) {
				toast({
					title: "Was sollen wir versenden?",
					description:
						"Wenn keine Datei angegeben ist, muss ein Nachrichtentext vorhanden sein. Oder umgekehrt.",
					status: "info",
					duration: 4000,
					isClosable: true,
				});
				return false;
			}
			setCurrentStep(currentStep + 1);
		}
	};

	// Submit the data
	const submitData = async messageCode => {
		if (messageCode) {
			const data = {
				messageCode,
				ncode,
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/nfc/join`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
				}
			);

			const result = await response.json();
			if (response.ok) {
				if (result.success) {
					setCurrentStep(currentStep + 1);
				} else {
					toast({
						title: "Error",
						description:
							result.error +
							". Kontaktiere den Support, wenn Du Dir unsicher bist.",
						status: "error",
						duration: 4000,
						isClosable: true,
					});
				}
			} else {
				toast({
					title: "Error",
					description:
						result.error +
						". Kontaktiere den Support, wenn Du Dir unsicher bist.",
					status: "error",
					duration: 4000,
					isClosable: true,
				});
			}
		} else {
			//		message, date, fileName, email, ncode} = req.body;

			const data = {
				ncode,
				email: userInputs.email,
				fileName: userInputs.fileName,
				date: userInputs.date,
				message: userInputs.message,
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/nfc/newmessage`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
				}
			);
			const result = await response.json();
			if (response.ok) {
				if (result.success) {
					setCurrentStep(currentStep + 1);
				} else {
					toast({
						title: "Error",
						description:
							result.error +
							". Kontaktiere den Support, wenn Du Dir unsicher bist.",
						status: "error",
						duration: 4000,
						isClosable: true,
					});
				}
			} else {
				toast({
					title: "Error",
					description:
						result.error +
						". Kontaktiere den Support, wenn Du Dir unsicher bist.",
					status: "error",
					duration: 4000,
					isClosable: true,
				});
			}
		}
	};
	const steps = [
		{
			heading: "Aktiviere die Ewigkeit!",
			component: (
				<>
					<Image src={"/images/phoneorange.jpg"}></Image>
					<Text>
						Hey, lass uns loslegen und Dein Schmuckstück mit einem
						ewigen Funkspruch ans Universum verknüpfen!
					</Text>
					<Text>
						Nimm Dir 5-10 Minuten Zeit, um Dir in Ruhe eine
						Nachricht auszudenken.
					</Text>
					<Text>
						Und jetzt viel Spaß beim Versenden Deiner Nachricht!
					</Text>
					<Text mt={2}>Dein DearUniverse-Team!</Text>
				</>
			),
			buttons: [
				{
					text: "Los geht's!",
					variant: "solid",
					onClick: () => {
						setCurrentStep(currentStep + 1);
						window.scrollTo({top: 0, behavior: "instant"});
					},
				},
			],
		},
		{
			heading: "Deine Nachricht ans Universum",
			component: (
				<>
					<Text>
						Gleich kannst Du Deinen ganz persönlichen Funkspruch ins
						Weltall senden!
					</Text>
					<Text>
						Solltest Du schon einmal bei DearUniverse bestellt
						haben, kannst Du auch Deine bestehende Nachricht mit
						Deinem Schmuckstück verknüpfen.
					</Text>
				</>
			),
			buttons: [
				{
					text: "Neue Nachricht erstellen",
					variant: "solid",
					onClick: () => {
						setMessageExists(false);
						setCurrentStep(currentStep + 1);
						window.scrollTo({top: 0, behavior: "instant"});
					},
				},
				{
					text: "Ich habe bereits eine Nachricht",
					variant: "outline",
					size: "sm",
					onClick: () => {
						setMessageExists(true);
						setCurrentStep(currentStep + 1);
						window.scrollTo({top: 0, behavior: "instant"});
					},
				},
			],
		},
		{
			heading: "Deine Nachricht ans Universum",
			component: messageExists ? (
				<ExistingMessage
					checkData={checkData}
					messageCode={userInputs.messageCode}
					changeUserInputs={changeUserInputs}
				/>
			) : (
				<NewMessage
					setUserInputs={setUserInputs}
					file={file}
					setFile={setFile}
					checkData={checkData}
					userInputs={userInputs}
					changeUserInputs={changeUserInputs}
				/>
			),
			buttons: [
				{
					text: "Überprüfen",
					variant: "solid",
					onClick: () => {
						checkData();
						window.scrollTo({top: 0, behavior: "instant"});
					},
				},
				{
					text: "Zurück",
					variant: "outline",
					size: "sm",
					onClick: () => {
						setCurrentStep(currentStep - 1);
						window.scrollTo({top: 0, behavior: "instant"});
					},
				},
			],
		},
		{
			heading: "Überprüfe Deine Nachricht",
			component: <CheckMessage userInputs={userInputs} />,
			buttons: [
				{
					text: messageExists
						? "Nachricht jetzt verknüpfen!"
						: "Speichern und verknüpfen!",
					variant: "solid",
					onClick: () => {
						submitData(userInputs.messageCode, userInputs.email);
						window.scrollTo({top: 0, behavior: "instant"});
					},
				},
				{
					text: "Zurück",
					variant: "outline",
					size: "sm",
					onClick: () => {
						window.scrollTo({top: 0, behavior: "instant"});
						setCurrentStep(currentStep - 1);
					},
				},
			],
		},
		{
			heading: "Auf geht die Reise...",
			component: (
				<>
					<Image src="/images/pointorange.jpeg" />
					<Text>
						Super, Dein Schmuckstück ist nun mit Deiner Nachricht
						verknüpft.
					</Text>
					<Heading as={"h2"} fontSize={"lg"}>
						Nächste Schritte:
					</Heading>

					<strong>1. Erneut scannen</strong>
					<Text>
						Scanne als Nächstes erneut Dein Schmuckstück und Du
						kommst direkt zu Deinem persönlichen Dashboard!
					</Text>

					<strong>2. Ein kleiner Gefallen...</strong>
					<Text>
						Als Onlineshop sind wir auf Bewertungen angewiesen. Wenn
						Du gerade noch 2 Minuten Zeit hast, würden wir uns sehr
						über eine Bewertung bei Trustpilot freuen!
					</Text>
					<Text fontWeight={"bold"}>
						Mit jeder Bewertung hilfst Du uns ungemein!
					</Text>
					<Text fontSize={"xs"} opacity={"0.8"}>
						Keine Registrierung nötig! Max. 2 Minuten!
					</Text>
				</>
			),
			buttons: [
				{
					text: "2-Minuten-Bewertung",
					variant: "solid",
					onClick: () => {
						window.location.href =
							"https://de.trustpilot.com/evaluate/dearuniverse.de";
					},
				},
			],
		},
	];
	if (blankScreen) return "";

	return (
		<>
			{steps[currentStep] && (
				<Screen
					setCurrentStep={setCurrentStep}
					{...steps[currentStep]}
				/>
			)}
		</>
	);
}
