import {
	Flex,
	Input,
	VStack,
	Text,
	Textarea,
	FormLabel,
	Box,
} from "@chakra-ui/react";
import React from "react";
import DateTimePicker from "./DateTimePicker";
import FileUpload from "./FileUpload";

export default function NewMessage({
	changeUserInputs,
	checkData,
	userInputs,
	setFile,
	file,
	setUserInputs,
}) {
	return (
		<Flex direction="column" gap={7} align="left" textAlign={"left"} mb={5}>
			<Box>
				<FormLabel>1. Verfasse Deinen Funkspruch:</FormLabel>
				<Textarea
					id="nachricht"
					placeholder="Deine Nachricht"
					value={userInputs.message}
					onChange={e => {
						changeUserInputs("message", e.target.value);
					}}
				/>
			</Box>

			<Box>
				<FormLabel>2. Wähle ein Datum und eine Uhrzeit:</FormLabel>
				<DateTimePicker
					date={userInputs.date}
					keyName={"date"}
					change={changeUserInputs}
				/>
			</Box>

			<Box>
				<FormLabel>
					3. Füge ein Foto oder Video hinzu (optional)
				</FormLabel>

				<FileUpload
					setUserInputs={setUserInputs}
					userInputs={userInputs}
					file={file}
					setFile={setFile}
				/>
			</Box>
		</Flex>
	);
}
