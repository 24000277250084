import {FormControl, Input, Text} from "@chakra-ui/react";
import React from "react";

export default function ExistingMessage({
	messageCode,
	changeUserInputs,
	checkData,
}) {
	return (
		<>
			<FormControl>
				<Text>
					Gib hier Deinen Code ein. Den Code findest Du auf dem
					Zertifikat oder in der Bestätigungsmail, neben dem Link zum
					Dashboard.
				</Text>
				<Input
					id="codeInput"
					type="text"
					placeholder="Dein Code"
					value={messageCode}
					onChange={e => {
						changeUserInputs("messageCode", e.target.value);
					}}
					onKeyDown={e => {
						if (e.key === "Enter") {
							checkData();
						}
					}}
				/>
			</FormControl>
		</>
	);
}
