import {Box} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import Control from "./Control";

export default function Main({focusLy, date, setMileStones,noUi}) {
	const iframe = useRef(null);
	const [frameReady, setFrameReady] = useState(false)
	const frameReadyRef = useRef(frameReady);
	frameReadyRef.current = frameReady;
	//window.p5 = iframe?.current?.contentWindow;

	function isIframeReady(){
		return (frameReadyRef.current)
	}


	function dispatchCommand(command, data) {
		if (!isIframeReady()) {
			setTimeout(() => {
				dispatchCommand(command, data);
			}, 500);
		}
		iframe.current.contentWindow.postMessage(
			JSON.stringify({command, data}),
			"*"
		);
	}

	useEffect(() => {
		dispatchCommand("focus", focusLy);
	}, [focusLy]);

	useEffect(() => {
		if (date !== 0) {
			dispatchCommand("setDate", date);
		}
	}, [date]);

	useEffect(() => {
		function handleAnimationData(message) {
			if (message.origin !== process.env.REACT_APP_ANIMATION_URL.split("/").slice(0,3).join("/")) return;
			
			if (!frameReadyRef.current && message.data === "ready") {
				setFrameReady(true);
				return;
			}
			
			if (message.data?.[0]?.name) {
				setMileStones(message.data);
				return;
			}
			//console.log(message.data.nextMilestone);
			//setAnimationData(message.data);
			function secondsToYdhms(sec) {
				sec = Number(sec);
				var y = Math[sec > 0 ? "floor" : "ceil"](
					sec / (3600 * 24 * 365)
				);
				var d = Math[sec > 0 ? "floor" : "ceil"](
					(sec % (3600 * 24 * 365)) / (3600 * 24)
				);
				var h = Math[sec > 0 ? "floor" : "ceil"](
					(sec % (3600 * 24)) / 3600
				);
				var m = Math[sec > 0 ? "floor" : "ceil"]((sec % 3600) / 60);
				var s = Math[sec > 0 ? "floor" : "ceil"](sec % 60);

				var yDisplay = y + (y == 1 ? " Jahr, <br>" : " Jahre, <br>");
				var dDisplay = d + (d == 1 ? " Tag, <br>" : " Tage, <br>");
				var hDisplay =
					h + (h == 1 ? " Stunde, <br>" : " Stunden, <br>");
				var mDisplay =
					m + (m == 1 ? " Minute, <br>" : " Minuten, <br>");
				var sDisplay = s + (s == 1 ? " Sekunde" : " Sekunden");
				return yDisplay + dDisplay + hDisplay + mDisplay + sDisplay;
			}
			try {
				if(noUi) return;
				document.getElementById("stat_time").innerHTML = secondsToYdhms(
					Number(message.data.seconds / 30n)
				);
				let km = message.data.traveldist / 1000n;
				let ly = kLy(message.data.traveldist / 1000n);
				document.getElementById("stat_km").innerHTML =
					(km > 0 ? km : 0n).toLocaleString() + " Kilometer";
				document.getElementById("stat_ly").innerHTML =
					(ly > 0 ? ly : 0n).toLocaleString("DE", {
						minimumFractionDigits: ly > 0 ? 8 : 0,
					}) + " Lichtjahre";
			} catch (e) {
				console.error(e)
			}

			window.animationData = message.data;
		}
		window.addEventListener("message", handleAnimationData);
		return () => {
			window.removeEventListener("message", handleAnimationData);
		};
	}, []);

	return (
		<>
			<Box flex={"1"} id="animationIFrame">
				<iframe
					title="animation"
					ref={iframe}
					src={process.env.REACT_APP_ANIMATION_URL}
					width={"100%"}
					height="100%"></iframe>
			</Box>
			{!noUi && <Control dispatchCommand={dispatchCommand} />}
		</>
	);
}

function kLy(km) {
	let lichtjahr = 9.461 * Math.pow(10, 12);
	return Number(km) / lichtjahr;
}
