import {Box, Flex, Text} from "@chakra-ui/react";
import {
	faArrowsH,
	faClock,
	faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export default function Stats({animationData}) {
	return (
		<Box>
			<StatItem id="stat_time" icon={faClock}></StatItem>
			<StatItem id="stat_km" icon={faArrowsH}></StatItem>
			<StatItem id="stat_ly" icon={faLightbulb}></StatItem>
		</Box>
	);
}

function StatItem({icon, id}) {
	return (
		<Flex mb={4}>
			<Box mr={2} w={"20px"}>
				<FontAwesomeIcon icon={icon}></FontAwesomeIcon>
			</Box>
			<Box>
				<Text color={"white"} id={id}>
					-
				</Text>
			</Box>
		</Flex>
	);
}
